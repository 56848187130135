<template>
  <section id="category-development">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="10" md="10">
          <v-select v-model="selectedCategories" label="category" multiple :options="allCategories"
            @input="changeselectedCategories" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showCategoriesDevelopment" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Categories development
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-categories-development" />
          </h4>
          <b-popover target="popover-categories-development" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="categoriesDevelopmentChart" :options="categoriesDevelopmentChart"
          style="width: 100%; height: 500px;" />
      </b-card>
    </b-overlay>
    <b-row>
      <b-col xl="6" md="6">
        <b-overlay :show="showBestWorstCategories" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Best improved categories
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-best-improved-categories" />
              </h4>
              <b-popover target="popover-best-improved-categories" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="bestImprovedCatChart" :options="bestImprovedCatChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6" md="6">
        <b-overlay :show="showBestWorstCategories" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Worst improved categories
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-worst-improved-categories" />
              </h4>
              <b-popover target="popover-worst-improved-categories" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="worstImprovedCatChart" :options="worstImprovedCatChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6" md="6">
        <b-overlay :show="showBestWorstCategoriesByYear" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Best improved categories compared to last year
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-best-improved-categories-by-year" />
              </h4>
              <b-popover target="popover-best-improved-categories-by-year" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="bestImprovedCatByYearChart" :options="bestImprovedCatByYearChart"
              style="width: 100%; height: 400px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="bestImprovedCatByYearTable.currentPage" :per-page="10"
                :items="bestImprovedCatByYearTable.items" :fields="bestImprovedCatByYearTable.fields"
                :sort-by.sync="bestImprovedCatByYearTable.sortBy" :sort-desc.sync="bestImprovedCatByYearTable.sortDesc"
                :sort-direction="bestImprovedCatByYearTable.sortDirection" :filter="bestImprovedCatByYearTable.filter"
                :filter-included-fields="bestImprovedCatByYearTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6" md="6">
        <b-overlay :show="showBestWorstCategoriesByYear" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Worst improved categories compared to last year
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-worst-improved-categories-by-year" />
              </h4>
              <b-popover target="popover-worst-improved-categories-by-year" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="worstImprovedCatByYearChart" :options="worstImprovedCatByYearChart"
              style="width: 100%; height: 400px;" />
            <b-card no-body>
              <b-table striped hover responsive class="position-relative"
                :current-page="worstImprovedCatByYearTable.currentPage" :per-page="10"
                :items="worstImprovedCatByYearTable.items" :fields="worstImprovedCatByYearTable.fields"
                :sort-by.sync="worstImprovedCatByYearTable.sortBy"
                :sort-desc.sync="worstImprovedCatByYearTable.sortDesc"
                :sort-direction="worstImprovedCatByYearTable.sortDirection" :filter="worstImprovedCatByYearTable.filter"
                :filter-included-fields="worstImprovedCatByYearTable.filterOn">
              </b-table>
            </b-card>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BPopover,
  BOverlay,
  BTable,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BCard,
    BCardHeader,
    BPopover,
    BOverlay,
    BTable,
    flatPickr,
    vSelect,
    ECharts,
  },
  data() {
    return {
      showCategoriesDevelopment: true,
      showBestWorstCategories: true,
      showBestWorstCategoriesByYear: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedCategories: [],
      allCategories: [],
      queryParamsCategoriesDevelopment: {},
      queryParamsBestWorstCategories: {},
      categoriesDevelopmentChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      bestImprovedCatChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      worstImprovedCatChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      bestImprovedCatByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} %<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      bestImprovedCatByYearTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover_change_pct',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'category', label: 'category' },
          {
            key: 'turnover_curr',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_prev',
            label: 'turnover prev. year same period',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_change',
            label: 'change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_change_pct',
            label: 'percentage change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      worstImprovedCatByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} %<br/>` : '';
            });
            return result;
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      worstImprovedCatByYearTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'turnover_prev',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'category', label: 'category' },
          {
            key: 'turnover_curr',
            label: 'turnover current year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_prev',
            label: 'turnover prev. year same period',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_change',
            label: 'change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_change_pct',
            label: 'percentage change',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsCategoriesDevelopment.from_date = dateRange[0];
    this.queryParamsCategoriesDevelopment.to_date = dateRange[1];
    this.queryParamsBestWorstCategories.from_date = dateRange[0];
    this.queryParamsBestWorstCategories.to_date = dateRange[1];

    try {
      await this.getAllCategories();
      await this.getTopCategories();
      this.queryParamsCategoriesDevelopment.categories = this.selectedCategories.map(item => item.category_id);
      await this.getCategoriesDevelopment();
      await this.getTopCategoriesByYear();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    updateChartsBestWorstCategories(data) {
      this.bestImprovedCatChart.xAxis.data = data[0].data.results.map(item => item.category);
      this.bestImprovedCatChart.series = {
        name: 'Turnover',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[0].data.results.map(item => item.turnover),
      };
      this.worstImprovedCatChart.xAxis.data = data[1].data.results.map(item => item.category);
      this.worstImprovedCatChart.series = {
        name: 'Turnover',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[1].data.results.map(item => item.turnover),
      };
      this.selectedCategories = data[0].data.results.map(item => ({ category_id: item.category_id, category: item.category }));
    },
    updateChartsBestWorstCategoriesByYear(data) {
      this.bestImprovedCatByYearChart.xAxis.data = data[0].data.results.map(item => item.category);
      this.bestImprovedCatByYearChart.series = {
        name: 'Turnover Change',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[0].data.results.map(item => item.turnover_change_pct),
      };
      this.bestImprovedCatByYearTable.items = data[0].data.results;

      this.worstImprovedCatByYearChart.xAxis.data = data[1].data.results.map(item => item.category);
      this.worstImprovedCatByYearChart.series = {
        name: 'Turnover Change',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[1].data.results.map(item => item.turnover_change_pct),
      };
      this.worstImprovedCatByYearTable.items = data[1].data.results;
    },
    updateChartsCategoriesDevelopment(data) {
      let turnoverMonthYearLabels = data.data.results.map(item => item.month_year);
      turnoverMonthYearLabels = [...new Set(turnoverMonthYearLabels)];
      const groupedByCategory = data.data.results.reduce((acc, curr) => {
        const key = curr.category
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      }, {});

      let turnoverByCategoryDatasets = [];
      let c = 0;
      for (const key in groupedByCategory) {
        let monthYearValues = groupedByCategory[key].map(item => item.month_year);
        let turnoverValues = groupedByCategory[key].map(item => item.turnover);
        let turnoverData = [];
        for (var i = 0; i < turnoverMonthYearLabels.length; i++) {
          let foundValue = false;
          for (var j = 0; j < monthYearValues.length; j++) {
            if (turnoverMonthYearLabels[i] == monthYearValues[j]) {
              turnoverData.push(turnoverValues[j]);
              foundValue = true;
              break;
            }
          }
          if (!foundValue) {
            turnoverData.push(0.0);
          }
        }
        turnoverByCategoryDatasets.push({
          name: key,
          type: 'line',
          areaStyle: {},
          data: turnoverData,
        });
        c += 1;
      }

      this.categoriesDevelopmentChart.xAxis.data = turnoverMonthYearLabels;
      this.categoriesDevelopmentChart.series = turnoverByCategoryDatasets;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllCategories() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-categories/`, {});
        this.allCategories = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getTopCategories() {
      this.showBestWorstCategories = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/best-improved-categories/`, this.queryParamsBestWorstCategories),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/worst-improved-categories/`, this.queryParamsBestWorstCategories),
        ]);
        this.updateChartsBestWorstCategories(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showBestWorstCategories = false;
      }
    },
    async getTopCategoriesByYear() {
      this.showBestWorstCategoriesByYear = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/best-improved-categories-by-year/`, {}),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/worst-improved-categories-by-year/`, {}),
        ]);
        this.updateChartsBestWorstCategoriesByYear(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showBestWorstCategoriesByYear = false;
      }
    },
    async getCategoriesDevelopment() {
      this.showCategoriesDevelopment = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/category-development/`, this.queryParamsCategoriesDevelopment);
        this.updateChartsCategoriesDevelopment(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showCategoriesDevelopment = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsCategoriesDevelopment.from_date = dateRange[0];
        this.queryParamsCategoriesDevelopment.to_date = dateRange[1];
        this.queryParamsBestWorstCategories.from_date = dateRange[0];
        this.queryParamsBestWorstCategories.to_date = dateRange[1];
        await this.getTopCategories();
        this.queryParamsCategoriesDevelopment.categories = this.selectedCategories.map(item => item.category_id);
        await this.getCategoriesDevelopment();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.categoriesDevelopmentChart.refresh();
          this.$refs.bestImprovedCatChart.refresh();
          this.$refs.worstImprovedCatChart.refresh();
        });
      }
    },
    async changeselectedCategories() {
      this.queryParamsCategoriesDevelopment.categories = this.selectedCategories.map(item => item.category_id);
      await this.getCategoriesDevelopment();

      this.$nextTick(() => {
        this.$refs.categoriesDevelopmentChart.refresh();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
